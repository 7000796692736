import { useMemo } from "react";
import { globalConstants } from "./globalConstants";

export const FloatingFace: React.FC<{ isDone: boolean }> = ({ isDone }) => {
  const rect = useMemo(
    () => ({
      bottom: `${Math.random() * 100}%`,
      left: `${Math.random() * 100}%`,
      width: `${Math.max(50, Math.random() * 160)}px`,
    }),
    []
  );
  return (
    <div
      style={{
        fontSize: "7rem",
        transition: "transform 4s ease-in, opacity 2s ease-in",
        // animationName:  'floatAway',
        opacity: !isDone ? 0 : 1,
        pointerEvents: !isDone ? "none" : undefined,
        animationFillMode: "forwards",
        animationDuration: "4s",
        position: "fixed",
        ...rect,
        transform: isDone
          ? `translateY(-${Math.max(5000 * Math.random(), 2000)}px)`
          : undefined,
      }}
    >
      {globalConstants.emoji}
    </div>
  );
};
