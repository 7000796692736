import { useEffect, useMemo, useRef, useState } from "react";
import { globalConstants } from "./globalConstants";
import { ConfettiCannon } from "./ReactConfetti";

const answer = globalConstants.puzzleAnswer;

export const NUMBER_OF_GUESSES = answer.length;
export type Guess = [string, string, string, string, string];

export const Row: React.FC<{ style?: React.CSSProperties }> = ({
  children,
  style,
}) => {
  return (
    <div
      style={{
        display: "flex",
        columnGap: 4,
        justifyContent: "center",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const Tile: React.FC<{
  guessAnswerWord?: string[];
  guessAnswerLetter?: string;
  letter?: string;
  onCorrect?: () => void;
  isGuess?: boolean;
}> = ({ letter, guessAnswerLetter, guessAnswerWord, onCorrect, isGuess }) => {
  const [animationCounter, setAnimationCounter] = useState(0);
  const pastGuessStyles = useMemo(() => {
    if (!guessAnswerLetter || !guessAnswerWord) return setAnimationCounter(0);
    if (guessAnswerLetter === letter)
      return { color: "white", background: "#6aaa64" };
    if (guessAnswerLetter !== letter) {
      if (guessAnswerWord.includes(letter!))
        return { color: "white", background: "#c9b458" };
      return { color: "white", background: "#939598" };
    }
  }, [guessAnswerLetter, guessAnswerWord, letter]);

  const [animationName, setAnimationName] = useState<string>("FlipIn");

  const animationProps: React.CSSProperties =
    pastGuessStyles && isGuess && animationCounter < 2 && animationName
      ? {
          animationName,
          animationDuration: ".3s",
          animationFillMode: "forwards",
          animationTimingFunction: "ease-in",
          animationIterationCount: 1,
        }
      : {
          // animationName: 'FlipIn',
        };

  const canUseColorTiles = animationCounter >= 1 || !isGuess;

  const getBorderStyle = () => {
    if (!isGuess && pastGuessStyles) {
      return "2px solid transparent";
    }
    if (isGuess && canUseColorTiles) return "2px solid transparent";
    return "2px solid lightgrey";
  };

  return (
    <div
      onAnimationEnd={(e) => {
        if (e.animationName === "FlipIn") {
          setAnimationCounter((count) => count + 1);
          setAnimationName("FlipOut");
        }
        if (e.animationName === "FlipOut") {
          setAnimationCounter((count) => count + 1);
          setAnimationName("FlipIn");
          onCorrect?.();
        }
      }}
      style={{
        // transition: 'background .5s ease',
        // borderRadius: 3,
        // flexGrow: 0,
        // flexShrink: 0,
        width: 50,
        height: 50,
        border: getBorderStyle(),
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
        fontWeight: 600,
        display: "flex",
        fontSize: 28,
        ...animationProps,
        ...(canUseColorTiles ? pastGuessStyles : {}),
      }}
    >
      {letter}
    </div>
  );
};

export const Board: React.FC<{
  setDone: (boolea: boolean) => void;
  onCorrectGuess: () => void;
  checkCurrentGuess: boolean;
  currentGuess: string;
  pastGuesses: Guess[];
  setCheckCurrentGuess: (flag: boolean) => void;
}> = ({
  setDone,
  onCorrectGuess,
  checkCurrentGuess,
  currentGuess,
  pastGuesses,
  setCheckCurrentGuess,
}) => {
  // unintutive but zero index
  const currentGuessIndex = pastGuesses.length;
  // minus the current guess row
  const numberOfRemaingGuesses = NUMBER_OF_GUESSES - pastGuesses.length - 1;

  const currentAnswer = answer[currentGuessIndex];
  const currentGuessRow = [
    // currentGuess[0],
    // currentGuess[1],
    // currentGuess[2],
    // currentGuess[3],
    // currentGuess[4],
    ...(currentAnswer || []).map((letter, index) => {
      if (currentGuess[index]) return currentGuess[index];
      return "";
    }),
  ];

  const [currentlyCheckingAnswer, setCurrentlyCheckingAnswer] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (!checkCurrentGuess) return;
    const answerWord = answer[currentGuessIndex];
    setCheckCurrentGuess(false);

    // TODO
    for (let i = 0; i < answerWord.length; i++) {
      setTimeout(() => {
        setCurrentlyCheckingAnswer(answerWord.slice(0, i + 1));
        // TODO
        if (i === answerWord.length - 1) {
          if (currentGuess === answerWord.join("")) {
            setTimeout(() => {
              // eslint-disable-next-line no-console
              console.log("correct!");
              if (currentGuessIndex === answer.length - 1) return setDone(true);
              // onCorrectGuess()
              // setCurrentlyCheckingAnswer([])
            }, 2000);
          } else {
            // try again
            setTimeout(() => {
              // eslint-disable-next-line no-console
              console.log("clearing");
              setCurrentlyCheckingAnswer([]);
            }, 2000);
          }
        }
      }, i * 500);
    }
  }, [
    setDone,
    checkCurrentGuess,
    currentGuessIndex,
    setCheckCurrentGuess,
    setCurrentlyCheckingAnswer,
    onCorrectGuess,
    currentGuess,
  ]);

  const rowIterator =
    numberOfRemaingGuesses > 0
      ? new Array(numberOfRemaingGuesses).fill("placeholder")
      : [];

  const canonRef = useRef({ fire: () => {} });

  const onCorrect = () => {
    const answerWord = answer[currentGuessIndex];
    if (currentGuess === answerWord.join("")) {
      onCorrectGuess();
      setCurrentlyCheckingAnswer([]);
      canonRef.current.fire();
    }
  };

  const getGuessRow = () => {
    if (currentGuessIndex === answer.length) return null;
    return (
      <Row
        // terrible hack
        key={currentAnswer.join("")}
      >
        {currentGuessRow.map((letter, letterIndex) => {
          const isLast = letterIndex === currentAnswer.length - 1; // TODO 5
          // console.log('isLast', JSON.stringify(isLast, null, 2))
          return (
            <Tile
              isGuess
              onCorrect={isLast ? onCorrect : undefined}
              guessAnswerWord={
                currentlyCheckingAnswer[letterIndex] &&
                currentGuess.length === currentAnswer.length // TODO 5
                  ? answer[currentGuessIndex]
                  : undefined
              }
              guessAnswerLetter={
                currentGuess.length === currentAnswer.length // TODO 5
                  ? currentlyCheckingAnswer[letterIndex]
                  : undefined
              }
              // eslint-disable-next-line react/no-array-index-key
              key={letterIndex}
              letter={letter}
            />
          );
        })}
      </Row>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
        backgroundColor: "white",
      }}
    >
      <ConfettiCannon ref={canonRef} />
      {pastGuesses.map((pastGuess, guessIndex) => {
        return (
          <Row key={pastGuess.join("")}>
            {Array.from(pastGuess).map((letter, letterIndex) => {
              return (
                <Tile
                  guessAnswerWord={answer[guessIndex]}
                  guessAnswerLetter={answer[guessIndex][letterIndex]}
                  letter={letter}
                  // eslint-disable-next-line react/no-array-index-key
                  key={letter + letterIndex}
                />
              );
            })}
          </Row>
        );
      })}
      {getGuessRow()}
      {rowIterator.map((_, rowIndex) => {
        const guessLength = answer[pastGuesses.length + 1 + rowIndex].length;
        const letterIterator = new Array(guessLength).fill("placeholder");
        return (
          <Row>
            {letterIterator.map((_unused, index) => {
              // eslint-disable-next-line react/no-array-index-key
              return <Tile key={index} />;
            })}
          </Row>
        );
      })}
    </div>
  );
};
