import { useEffect } from "react";

const Key: React.FC<{
  letter: string;
  onChange: (letter: string) => void;
}> = ({ letter, onChange }) => {
  useEffect(() => {
    const keyDownHandler = (ev: KeyboardEvent) => {
      const lowerCaseLetter = ev.key.toLowerCase();
      if (lowerCaseLetter === letter) {
        onChange(letter);
      }
    };
    window.addEventListener("keydown", keyDownHandler);
    return () => window.removeEventListener("keydown", keyDownHandler);
  }, [letter, onChange]);

  return (
    <div
      onClick={() => onChange(letter)}
      style={{
        cursor: "pointer",
        background: "lightgray",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 30,
        height: 50,
        textTransform: "capitalize",
        fontWeight: 600,
        borderRadius: 3,
      }}
    >
      {letter}
    </div>
  );
};

const Enter: React.FC<{
  enter: () => void;
}> = ({ enter }) => {
  useEffect(() => {
    const keyDownHandler = (ev: KeyboardEvent) => {
      if (ev.key === "Enter") {
        enter();
      }
    };
    window.addEventListener("keydown", keyDownHandler);
    return () => window.removeEventListener("keydown", keyDownHandler);
  }, [enter]);
  return (
    <div
      onClick={() => enter()}
      style={{
        cursor: "pointer",
        background: "lightgray",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 30,
        height: 50,
        textTransform: "capitalize",
        fontWeight: 600,
        borderRadius: 3,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      Enter
    </div>
  );
};

const Backspace: React.FC<{
  backspace: () => void;
}> = ({ backspace }) => {
  useEffect(() => {
    const keyDownHandler = (ev: KeyboardEvent) => {
      if (ev.key === "Backspace") {
        backspace();
      }
    };
    window.addEventListener("keydown", keyDownHandler);
    return () => window.removeEventListener("keydown", keyDownHandler);
  }, [backspace]);
  return (
    <div
      onClick={() => backspace()}
      style={{
        cursor: "pointer",
        background: "lightgray",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 30,
        height: 50,
        textTransform: "capitalize",
        fontWeight: 600,
        borderRadius: 3,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      Del
    </div>
  );
};

export const Keyboard: React.FC<{
  enter: () => void;
  backspace: () => void;
  onChange: (letter: string) => void;
}> = ({ onChange, backspace, enter }) => {
  const row1 = ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "-"];
  const row2 = ["a", "s", "d", "f", "g", "h", "j", "k", "l"];
  const row3 = ["z", "x", "c", "v", "b", "n", "m"];
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 4 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          columnGap: 4,
        }}
      >
        {row1.map((letter) => (
          <Key onChange={onChange} key={letter} letter={letter} />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          columnGap: 4,
        }}
      >
        {row2.map((letter) => (
          <Key onChange={onChange} key={letter} letter={letter} />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          columnGap: 4,
        }}
      >
        <Enter enter={enter} />
        {row3.map((letter) => (
          <Key onChange={onChange} key={letter} letter={letter} />
        ))}
        <Backspace backspace={backspace} />
      </div>
    </div>
  );
};
