import Modal from "react-modal";
import { Row, Tile } from "./Board";
import { globalConstants } from "./globalConstants";

const customStyles: Modal.Styles = {
  content: {
    inset: "unset",
    position: "unset",
    minHeight: "0%",
    minWidth: "0%",
    marginLeft: 12,
    marginRight: 12,
  },
  overlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const WelcomeModal: React.FC<{
  isOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isOpen, setModalOpen }) => {
  return (
    <Modal style={customStyles} isOpen={isOpen}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row
          style={{ marginBottom: 12, justifyContent: "center", width: "100%" }}
        >
          {globalConstants.modal.row1.map((letter, letterIndex) => {
            return (
              <Tile
                isGuess
                guessAnswerWord={globalConstants.modal.row1Answer}
                guessAnswerLetter={
                  globalConstants.modal.row1Answer[letterIndex]
                }
                letter={letter}
                // eslint-disable-next-line react/no-array-index-key
                key={letter + letterIndex}
              />
            );
          })}
        </Row>
        <Row style={{ justifyContent: "center", width: "100%" }}>
          {globalConstants.modal.row2.map((letter, letterIndex) => {
            return (
              <Tile
                isGuess
                guessAnswerWord={globalConstants.modal.row2Answer}
                guessAnswerLetter={
                  globalConstants.modal.row2Answer[letterIndex]
                }
                letter={letter}
                // eslint-disable-next-line react/no-array-index-key
                key={letter + letterIndex}
              />
            );
          })}
        </Row>
        <div>
          {globalConstants.holidayExclamation && (
            <p>{globalConstants.holidayExclamation}</p>
          )}
          <p>
            This puzzle was built just for you and is just like regular
            worlde...with a twist!
          </p>
          <p>
            You must solve each line of the puzzle correctly before you can move
            onto the next line.
          </p>
          <p>Solve the puzzle and unlock your prize!</p>
        </div>
        <button
          id="it-StartButton"
          style={{
            cursor: "pointer",
            border: "none",
            color: "white",
            background: "#6aaa64",
            fontSize: "1.5em",
            borderRadius: 4,
            paddingLeft: 8 * 2,
            paddingRight: 8 * 2,
            paddingTop: 8,
            paddingBottom: 8,
            fontWeight: 500,
          }}
          type="button"
          onClick={() => setModalOpen(false)}
        >
          Start
        </button>
        {globalConstants.modal.credit && (
          <p className="subtitle">{globalConstants.modal.credit}</p>
        )}
      </div>
    </Modal>
  );
};
